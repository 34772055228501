import { ProductCategory } from "@/models/entities/product-category.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "productcategory";

class ProductCategoriesService extends ApiService {
  getAllProductCategories() {
    return this.getAll<ProductCategory[]>(CONTROLLER);
  }
  getProductCategoryByCompanyID(id: number) {
    return this.getByID<number, ProductCategory[]>(
      `${CONTROLLER}/bycompany`,
      id
    );
  }
  //   getProductCategoryByID(id: number) {
  //     return this.getByID<number, ProductCategory>(CONTROLLER, id);
  //   }
  saveProductCategory(product: ProductCategory) {
    return this.post<ProductCategory, number>(CONTROLLER, product);
  }
  //   updateProductCategory(id: number, product: ProductCategory) {
  //     return this.updateById<number, ProductCategory, void>(
  //       CONTROLLER,
  //       id,
  //       product
  //     );
  //   }

  onDeleteCategory(id: number) {
    return this.getAll<unknown>(`${CONTROLLER}/${id}/ondelete`);
  }

  deleteProductCategory(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const productCategories = new ProductCategoriesService();
