
import { Product } from "@/models/entities/product.interface";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ProductCategory } from "@/models/entities/product-category.interface";
import { Supplier } from "@/models/entities/supplier.interface";
import { Tag } from "@/models/entities/tag.interface";
import { required } from "@/helpers/rules";
import { productCategories } from "@/services/api/productCategories.service";
import { tags } from "@/services/api/tags.service";
import { productPhoto } from "@/services/api/productPhoto.service";
import { ProductPhoto } from "@/models/entities/product-photo.interface";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
const overlayModule = getModule(OverlayModule, store);

@Component
export default class ProductForm extends Vue {
  /** Sets the form value */
  @Prop({ required: true }) private product: Product;
  /** Sets if the form is edit */
  @Prop({ required: true }) private isEdit: boolean;
  /** Sets the suppliers select value */
  @Prop({ required: true }) private suppliers: Supplier[];
  /** Sets the selected supplier */
  @PropSync("supplierId") private selectedSupplierId: number | null;
  /** Sets the selected customer type */
  @PropSync("productCategoryId") private selectedProductCategoryId:
    | number
    | null;
  /** Sets the selected Tags */
  @PropSync("selectedTagsId") private selectedTagsIdArr: number[];
  /** Sets the production days */
  @PropSync("productionDays") private _productionDays: [];
  /** Sets the production months */
  @PropSync("productionMonths") private _productionMonths: [];
  /** Sets the product files */
  @PropSync("files") private _files: File[];
  /** Sets the product default photo */
  @PropSync("file") private _file: File;
  /** Sets the product files */
  @PropSync("photoToDelete") private _photoToDelete: number[];

  private required = required;
  private months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  private days = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
  ];
  private productCategories: ProductCategory[] = [];
  private maxDisplay =
    this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
      ? 3
      : this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xs
      ? 2
      : 6;
  private carousel = 0;
  private tags = [] as Tag[];
  private productPhotosFromApi = [] as ProductPhoto[];
  private arrayBuffers = [] as ArrayBuffer[];
  private defaultUrl = null;

  private get urls(): string[] {
    let urls = [] as string[];
    if (
      this.arrayBuffers.length == this.productPhotosFromApi.length &&
      this.arrayBuffers.length != 0 &&
      this.productPhotosFromApi.length != 0
    ) {
      this.arrayBuffers.forEach((buffer) => {
        urls.push(
          window.URL.createObjectURL(
            new Blob([new File([buffer], "file")], { type: "image/*" })
          )
        );
      });
    }
    this._files.forEach((file) => {
      urls.push(
        window.URL.createObjectURL(new Blob([file], { type: "image/*" }))
      );
    });
    return urls;
  }

  private async mounted(): Promise<void> {
    this.$emit("validate");
    this.photo();
  }

  @Watch("product")
  private async getPhotos(): Promise<void> {
    this.photo();
  }

  @Watch("selectedSupplierId")
  async onSupplierChange(id: number): Promise<void> {
    if (id) {
      overlayModule.showOverlay();
      const pc = await productCategories.getProductCategoryByCompanyID(id);
      this.productCategories = pc.data;
    }
  }

  @Watch("selectedProductCategoryId")
  async onChangePC(id: number): Promise<void> {
    if (id) {
      overlayModule.showOverlay();
      const t = await tags.getTagByProductCategoryID(id);
      this.tags = t.data;
    }
  }

  private onChangeSupplier(): void {
    this.productCategories = [];
    this.selectedProductCategoryId = null;
    this.tags = [];
    this.selectedTagsIdArr = [];
  }

  private async addFiles(uploaded: File[]): Promise<void> {
    this._files = this._files.concat(uploaded);
  }

  private onChangeProductCategory(): void {
    this.tags = [];
    this.selectedTagsIdArr = [];
  }

  private remove(item: Tag) {
    const index = this.selectedTagsIdArr.indexOf(item.id);
    if (index >= 0) this.selectedTagsIdArr.splice(index, 1);
  }

  private async photo(): Promise<void> {
    if (this.product.id) {
      overlayModule.showOverlay();
      try {
        const [defaultPhoto, pp] = await Promise.all([
          productPhoto.getDefaultPhotoByProductId(this.product.id),
          productPhoto.getPhotoByProductId(this.product.id),
        ]);
        this.defaultUrl = window.URL.createObjectURL(
          new Blob([new File([defaultPhoto.data], "file")], { type: "image/*" })
        );
        let photos = pp.data;
        let promiseArr = photos.map((photo) => {
          this.productPhotosFromApi.push(photo);
          return productPhoto.getById(photo.id);
        });
        const results = await Promise.all(promiseArr);
        this.arrayBuffers = results.map((el) => el.data);
      } catch (error) {
        console.error;
      }
    }
  }

  private removePhoto(urlIdx: number): void {
    let fromApiFound = this.productPhotosFromApi[urlIdx];
    if (fromApiFound) {
      this._photoToDelete.push(fromApiFound.id);
      this.productPhotosFromApi.splice(urlIdx, 1);
      this.arrayBuffers.splice(urlIdx, 1);
      return;
    } else {
      this._files.splice(urlIdx - this.productPhotosFromApi.length, 1);
    }
  }

  private selectFile(): void {
    (
      (this.$refs.file as Vue).$refs.input as Vue & { click: () => void }
    ).click();
  }

  private selectDefaultFile(): void {
    (
      (this.$refs.defaultFile as Vue).$refs.input as Vue & { click: () => void }
    ).click();
  }

  private async onChangeDefaultFile(file: File): Promise<void> {
    await this.$nextTick();
    this.defaultUrl = window.URL.createObjectURL(
      new Blob([file], { type: "image/*" })
    );
  }
}
