import { ApiService } from "../api.service";
import axios from "axios";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import CancelTokenModule from "@/store/modules/cancelToken.module";
import { ProductPhoto } from "@/models/entities/product-photo.interface";
const cancelTokenModule = getModule(CancelTokenModule, store);

const CONTROLLER = "productphoto";

class ProductPhotoService extends ApiService {
  getPhotoByProductId(id: number) {
    const cancelToken = axios.CancelToken.source();
    cancelTokenModule.add(cancelToken);
    return this.getAll<ProductPhoto[]>(`${CONTROLLER}/byproduct/${id}`);
  }

  getDefaultPhotoByProductId(id: number) {
    const cancelToken = axios.CancelToken.source();
    cancelTokenModule.add(cancelToken);
    return this.getAll<ArrayBuffer>(`${CONTROLLER}/${id}/default`, {
      responseType: "arraybuffer",
    });
  }

  getPhotoByMeasureUnitTypeID(
    productPriceBookID: number,
    measureUnitTypeID: number
  ) {
    const cancelToken = axios.CancelToken.source();
    cancelTokenModule.add(cancelToken);
    return this.getAll<ArrayBuffer>(
      `${CONTROLLER}/${measureUnitTypeID}/${productPriceBookID}`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  getById(id: number) {
    const cancelToken = axios.CancelToken.source();
    cancelTokenModule.add(cancelToken);
    return this.getByID<number, ArrayBuffer>(`${CONTROLLER}`, id, {
      responseType: "arraybuffer",
    });
  }

  deleteById(id: number) {
    const cancelToken = axios.CancelToken.source();
    cancelTokenModule.add(cancelToken);
    return this.delete<number, void>(`${CONTROLLER}`, id);
  }
}

export const productPhoto = new ProductPhotoService();
