import { Tag } from "@/models/entities/tag.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "tag";

class TagsService extends ApiService {
  getAllTags() {
    return this.getAll<Tag[]>(CONTROLLER);
  }
  getTagByID(id: number) {
    return this.getByID<number, Tag>(CONTROLLER, id);
  }
  getTagByProductCategoryID(productCategoryID: number) {
    return this.getByID<number, Tag[]>(
      `${CONTROLLER}/bycategory`,
      productCategoryID
    );
  }
  saveTag(tag: Tag) {
    return this.post<Tag, number>(CONTROLLER, tag);
  }
  updateTag(id: number, tag: Tag) {
    return this.updateById<number, Tag, void>(CONTROLLER, id, tag);
  }
  deleteTag(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const tags = new TagsService();
