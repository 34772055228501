
import { DataTable } from "@/models/utils/datatable.interface";
import { Action } from "@/models/utils/table-action.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { Component, Vue } from "vue-property-decorator";
import ProductForm from "./ProductForm.vue";
import { products } from "@/services/api/products.service";
import { suppliers } from "@/services/api/suppliers.service";
import { Product } from "@/models/entities/product.interface";
import { Supplier } from "@/models/entities/supplier.interface";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { productPhoto } from "@/services/api/productPhoto.service";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({ components: { ProductForm } })
export default class Products extends Vue {
  private search = "";
  private valid = false;
  private dialogDelete = false;
  private productToDelete: number = null;
  private onDeleteMessage = "";
  private productDialog = {
    show: false,
    title: "",
    icon: "",
    product: {} as Product,
    suppliers: [] as Supplier[],
    selectedTagsId: [] as number[],
    isEdit: false,
    productionDays: [],
    productionMonths: [],
    productPhotoFiles: [] as File[],
    defaultFile: null,
    photoToDelete: [] as number[],
    hasFileError: false,
  };
  private headers: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Nome",
      align: "start",
      filterable: true,
      value: "productName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Categoria",
      align: "start",
      filterable: true,
      value: "categoryName",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Giacenza in kg",
      align: "start",
      filterable: true,
      value: "qtyStock",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Fornitore",
      align: "start",
      filterable: true,
      value: "companyName",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "In evidenza",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isFeatured",
      cellType: CellType.SWITCH,
      isPrimary: false,
      customText: "In evidenza",
      class: "llDataTable_header",
    },
  ];
  public items: DataTable<unknown> = {
    key: "id",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [
      { id: Action.EDIT, name: "edit" },
      { id: Action.DELETE, name: "delete" },
    ],
  };

  async created(): Promise<void> {
    await this.getTableItems();
  }

  async getTableItems(): Promise<void> {
    overlayModule.showOverlay();
    const r = await products.getAllProducts();
    this.items.loading = false;
    this.$set(this.items, "values", r.data);
  }

  private async onSwitched(item: Product): Promise<void> {
    overlayModule.showOverlay();
    await products.toggleIsFeatured(item.id);
    await this.getTableItems();
  }

  private async showProductDialog(item: Product): Promise<void> {
    this.$set(this.productDialog, "show", true);
    this.$set(this.productDialog, "icon", "mdi-basket");
    this.$set(this.productDialog, "productionDays", [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    this.$set(this.productDialog, "productionMonths", [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    if (item) {
      this.$set(this.productDialog, "title", "Modifica prodotto");
      this.$set(this.productDialog, "isEdit", true);
      this.$set(this.productDialog, "productPhotoFiles", [] as File[]);
      await Promise.all([
        this.getSuppliers(),
        this.getProduct(item.id),
        this.getSelectedTags(item.id),
      ]);
    } else {
      this.$set(this.productDialog, "title", "Nuovo prodotto");
      this.$set(this.productDialog, "isEdit", false);
      this.$set(this.productDialog, "product", {} as Product);
      this.$set(this.productDialog, "productPhotoFiles", [] as File[]);
      this.$set(this.productDialog, "defaultFile", null);
      this.$set(this.productDialog, "selectedTagsId", [] as number[]);
      await this.getSuppliers();
    }
  }

  private async getSuppliers(): Promise<void> {
    overlayModule.showOverlay();
    const s = await suppliers.getAllSuppliers();
    this.$set(this.productDialog, "suppliers", s.data);
  }

  private async getProduct(id: number): Promise<void> {
    overlayModule.showOverlay();
    const p = await products.getProductByID(id);
    this.$set(this.productDialog, "product", p.data);
    if (p.data.productionDays.length) {
      p.data.productionDays.forEach(
        (el) => (this.productDialog.productionDays[el] = true)
      );
    }
    if (p.data.productionMonths.length) {
      p.data.productionMonths.forEach(
        (el) => (this.productDialog.productionMonths[el] = true)
      );
    }
  }

  private async getSelectedTags(id: number): Promise<void> {
    let selectedTagsId = [];
    overlayModule.showOverlay();
    const t = await products.getTagsByProductID(id);
    if (t.data.length) {
      t.data.forEach((el) => selectedTagsId.push(el.id));
    }
    this.$set(this.productDialog, "selectedTagsId", selectedTagsId);
  }

  private validateForm(): void {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  private onSearch(value: string): void {
    this.$set(this.items, "search", value);
  }

  private closeProductDialog(): void {
    this.$set(this.productDialog, "show", false);
    this.$set(this.productDialog, "title", "");
    this.$set(this.productDialog, "icon", "");
    this.$set(this.productDialog, "product", {} as Product);
    this.$set(this.productDialog, "suppliers", [] as Supplier[]);
    this.$set(this.productDialog, "selectedTagsId", [] as number[]);
    this.$set(this.productDialog, "defaultFile", null);
    this.$set(this.productDialog, "isEdit", false);
    this.$set(this.productDialog, "productionDays", []);
    this.$set(this.productDialog, "productionMonths", []);
    this.$set(this.productDialog, "hasFileError", false);
  }

  private async saveProduct(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    let productionDaysId = [];
    let productionMonthsId = [];
    if (isValid) {
      if (
        this.productDialog.productionDays &&
        this.productDialog.productionDays.length
      ) {
        productionDaysId = this.productDialog.productionDays.reduce(
          (el, bool, idx) => (bool ? el.concat(idx) : el),
          []
        );
      }
      if (
        this.productDialog.productionMonths &&
        this.productDialog.productionDays.length
      ) {
        productionMonthsId = this.productDialog.productionMonths.reduce(
          (el, bool, idx) => (bool ? el.concat(idx) : el),
          []
        );
      }
      let p = {
        categoryID: this.productDialog.product.categoryID,
        productName: this.productDialog.product.productName,
        isEnabled: this.productDialog.product.isEnabled
          ? this.productDialog.product.isEnabled
          : true,
        productDescription: this.productDialog.product.productDescription
          ? this.productDialog.product.productDescription
          : null,
        expirationString: this.productDialog.product.expirationString
          ? this.productDialog.product.expirationString
          : null,
        conservationMethodString: this.productDialog.product
          .conservationMethodString
          ? this.productDialog.product.conservationMethodString
          : null,
        productionDays: productionDaysId,
        productionMonths: productionMonthsId,
        tags: this.productDialog.selectedTagsId,
        qtyStock: this.productDialog.product.qtyStock,
        isProductionDaysActive:
          this.productDialog.product.isProductionDaysActive,
        isProductionMonthsActive:
          this.productDialog.product.isProductionMonthsActive,
        isFeatured: this.productDialog.product.isFeatured,
      };
      if (this.productDialog.photoToDelete.length > 0) {
        this.productDialog.photoToDelete.forEach(async (id: number) => {
          await productPhoto.deleteById(id);
        });
        this.productDialog.photoToDelete.length = 0;
      }

      if (this.productDialog.product.id) {
        overlayModule.showOverlay();
        await products.updateProduct(
          this.productDialog.product.id,
          p,
          this.productDialog.productPhotoFiles,
          this.productDialog.defaultFile
        );
      } else {
        overlayModule.showOverlay();
        await products.saveProduct(
          p,
          this.productDialog.productPhotoFiles,
          this.productDialog.defaultFile
        );
      }
      this.getTableItems();
      this.closeProductDialog();
      snackbarModule.showSnackbar({
        message: "Salvataggio avvenuto con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private onDelete(item: Product): void {
    this.productToDelete = item.id;
    this.onDeleteMessage =
      "Sei sicuro di volere eliminare il prodotto " +
      item.productName.toUpperCase() +
      " ?";
    this.dialogDelete = true;
  }

  private async deleteProduct(): Promise<void> {
    overlayModule.showOverlay();
    await products.deleteProduct(this.productToDelete);
    this.getTableItems();
    this.dialogDelete = false;
    snackbarModule.showSnackbar({
      message: "Eliminazione avvenuta con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }
}
