import { Supplier } from "@/models/entities/supplier.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "company";

class SuppliersService extends ApiService {
  getAllSuppliers() {
    return this.getAll<Supplier[]>(CONTROLLER);
  }
  getSupplierByID(id: number) {
    return this.getByID<number, Supplier>(CONTROLLER, id);
  }
  saveSupplier(supplier: Supplier) {
    return this.post<Supplier, number>(CONTROLLER, supplier);
  }
  updateSupplier(id: number, supplier: Supplier) {
    return this.updateById<number, Supplier, void>(CONTROLLER, id, supplier);
  }
  deleteSupplier(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const suppliers = new SuppliersService();
